/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import AuthProvider from './auth';
import Login from "./login";

import styles from './layout.module.css';

const Layout = ({ children }) => {
  return (
    <AuthProvider>
      <div className="layout">
        <div className={styles.controls}>
          <Login />
        </div>
        <main>{children}</main>
        <footer>
          <p>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></p>
        </footer>
      </div>
    </AuthProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
