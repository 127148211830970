import _ from 'lodash/fp';
import React from 'react';

import Control from './control';
import Loading from '../loading';
import UserList from '../user-list';

import styles from './attendance.module.css';

const attendanceMessage = (userCount, limit) => {
  if (!limit) {
    return `${userCount}`;
  }

  if (userCount >= limit) {
    return 'Full';
  }

  return `${userCount} / ${limit}`;
}

const Attendance = (props) => {
  const {
    limit,
    loading,
    actions,
    includesMe,
    user,
    users: usersOption = [],
  } = props;

  if (loading) {
    return (
      <div className={styles.root}>
        <div className={styles.controls}><Control disabled /></div>
        <h2>Attending</h2>
        <Loading />
      </div>
    );
  }

  const users = limit ? _.take(limit, usersOption) : usersOption;
  const waitlisted = limit ? _.drop(limit, usersOption) : [];
  const userCount = _.size(users);
  const waitlistCount = _.size(waitlisted);
  const isFull = limit && (userCount >= limit);

  const controlProps = {
    isFull,
    includesMe,
    actions,
    disabled: !user,
  };

  return (
    <div className={styles.root}>
      <div className={styles.controls}>
        <Control {...controlProps} />
      </div>
      <h2>Attending ( {attendanceMessage(userCount, limit)} )</h2>
      <UserList users={users} />
      {!!waitlistCount && (
        <>
          <h2>Waitlist ( {waitlistCount} )</h2>
          <UserList className={styles.waitlist} users={waitlisted} />
        </>
      )}
    </div>
  );
}

export default Attendance;
