import React from 'react';

import useAttendanceWithMe from '../../state/attendance-with-me';
import { useAuth } from '../auth';

const getDisplayName = (WrappedComponent) => (
  WrappedComponent.displayName || WrappedComponent.name || 'Component'
);

const withState = (Component) => {
  const WithState = (props) => {
    const { event } = props;
    const state = useAttendanceWithMe(event);

    return (<Component {...props} {...state} />);
  }
  WithState.displayName = `WithState(${getDisplayName(Component)})`;

  return WithState;
};

export default withState;
