import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { navigate } from 'gatsby';

const DEFAULT_REDIRECT_CALLBACK = appState => {
  navigate(appState.returnTo, { replace: true });
};

const DEFAULT_OPTIONS = {
  domain: "dev-1b7gembu.auth0.com",
  client_id: "80Tzk2psK7ba1W6a1cxhFpphSxpy4etZ",
  audience: 'https://ypcqtqujo4.execute-api.us-east-1.amazonaws.com/dev/',
  redirect_uri: typeof window !== `undefined` && `${window.location.origin}/callback`,
};

export const Context = React.createContext();

export const useAuth = () => useContext(Context);

export const Provider = (props) => {
  const {
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    ...options
  } = props;

  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [client, setClient] = useState();
  const [loading, setLoading] = useState(true);

  const init = () => {
    const doInit = async () => {
      const client = await createAuth0Client({ ...DEFAULT_OPTIONS, ...options });
      setClient(client);

      if (window.location.search.includes("code=") &&
          window.location.search.includes("state=")) {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await client.isAuthenticated();
      const user = (isAuthenticated ? await client.getUser() : null);
      const token = (isAuthenticated ? await client.getTokenSilently({
        audience: 'https://ypcqtqujo4.execute-api.us-east-1.amazonaws.com/dev/'
      }) : null);

      setIsAuthenticated(isAuthenticated);
      setUser(user);
      setToken(token);
      setLoading(false);
    };
    doInit()
  };

  useEffect(init, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await client.handleRedirectCallback();
    const user = await client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  }

  return (
    <Context.Provider
      value={{
        // Values
        isAuthenticated,
        loading,
        token,
        user,

        // Actions
        handleRedirectCallback,
        login: () => client.loginWithRedirect({ appState: { returnTo: window.location.pathname } }),
        loginWithRedirect: (...args) => client.loginWithRedirect(...args),
        logout: (...args) => client.logout({ ...args, client_id: DEFAULT_OPTIONS.client_id }),
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider;
