import React from 'react';
import { graphql } from 'gatsby';

import Attendance from '../components/attendance';
import Layout from '../components/layout';
import SEO from '../components/seo';

const AttendanceTemplate = (props) => {
  const { data } = props;
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <header>
        <h1>{frontmatter.title}</h1>
        <div><time>{frontmatter.date}</time> @ <address>{frontmatter.address}</address></div>
      </header>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <Attendance event={frontmatter.slug} limit={frontmatter.limit} />
    </Layout>
  );
}

export default AttendanceTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        date(formatString: "dddd, MMMM Do, h:mm a")
        address
        limit
      }
    }
  }
`;
