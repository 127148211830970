import _ from 'lodash/fp';
import { useState, useEffect } from 'react';

import makeAPI from '../api';

const useAttendance = (id) => {
  const [attendance, setAttendance] = useState(null);

  const api = makeAPI();

  const setAttendanceFromResponse = _.compose(
    setAttendance,
    _.get('attendance.users')
  );

  const fetch = () => {
    api.fetchAttendance(id)
      .then(setAttendanceFromResponse)
  };

  useEffect(fetch, []);

  return {
    attendance,
    loading: attendance === null,
  };
};

export default useAttendance;
