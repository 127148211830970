
import React from 'react';

const getCaption = (includesMe, isFull) => (
  includesMe ?
    'LEAVE' :
    (isFull ?
      'WAITLIST' :
      'JOIN')
);

const Control = (props) => {
  const {
    actions = {},
    includesMe,
    isFull,
    ...otherProps
  } = props;
  const { markAttendance, unmarkAttendance } = actions;

  const action = (includesMe ? unmarkAttendance : markAttendance);
  const caption = getCaption(includesMe, isFull);

  return (
    <button
      {...otherProps}
      className="btn-primary"
      onClick={() => action()}
    >
      {caption}
    </button>
  );
};

export default Control;
