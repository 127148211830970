import _ from 'lodash/fp';
import classnames from 'classnames';
import React from 'react';

import styles from './user-list.module.css';

export const UserListItem = (props) => {
  const {
    user,
    className: classNameProp,
    ...otherProps
  } = props;

  const className = classnames(styles.item, classNameProp);

  return (
    <li {...otherProps} className={className}>
      <img alt="" src={user.picture} />
      {user.name}
    </li>
  )
}

export const UserList = (props) => {
  const {
    users,
    className: classNameProp,
    ...otherProps
  } = props;

  const className = classnames(styles.root, classNameProp);

  return (
    <ul {...otherProps} className={className}>
      {_.map(
        (user) => (<UserListItem key={user.sub || user.user_id} user={user} />),
        users
      )}
    </ul>
  );
};

export default UserList;
