import _ from 'lodash/fp';
import makeAPI from '../api';
import { useState } from 'react';
import useAttendance from './attendance';
import { useAuth } from '../components/auth';

const useAttendanceWithMe = (id) => {
  const { user, token } = useAuth();
  const user_id = _.get('sub', user);
  const api = makeAPI(token);

  const { attendance, loading } = useAttendance(id);
  const [shouldRemoveMe, setShouldRemoveMe] = useState(false);
  const [shouldAddMe, setShouldAddMe] = useState(false);

  const indexOfMe = _.findIndex(['user_id', user_id], attendance || []);
  const includesMe = (indexOfMe !== -1);

  const markAttendance = () => {
    setShouldAddMe(true);
    api.markAttendance(id, user_id);
  };

  const unmarkAttendance = () => {
    setShouldRemoveMe(true);
    setShouldAddMe(false);
    api.unmarkAttendance(id, user_id);
  };

  if (loading) {
    return {
      user,
      users: attendance,
      includesMe,
      loading,
      actions: {
        markAttendance,
        unmarkAttendance,
      }
    };
  }

  const addMe = (users) => [...users, user];
  const removeMe = _.pullAt(indexOfMe);

  const transform = _.compose(_.compact([
    shouldRemoveMe && removeMe,
    shouldAddMe && addMe,
    _.identity,
  ]));

  return {
    user,
    users: transform(attendance),
    includesMe: (!shouldRemoveMe && includesMe) || shouldAddMe,
    loading,
    actions: {
      markAttendance,
      unmarkAttendance,
    }
  };
}

export default useAttendanceWithMe;
