
import React from 'react';
import { useAuth } from './auth';

import styles from './login.module.css'

const Login = () => {
  const {
    isAuthenticated,
    user,
    loading,
    loginWithRedirect: login,
    logout
  } = useAuth();

  if (loading) {
    return null;
  }

  return (
    <div className={styles.root}>
      {!isAuthenticated && (
        <a href="#" onClick={() => login({ appState: { returnTo: window.location.pathname } })}>Login</a>
      )}

      {user && (<img alt="" src={user.picture} />) }
      {isAuthenticated && (
        <a href="#" onClick={() => logout({ returnTo: `${window.location.origin}/logout` })}>Logout</a>
      )}
    </div>
  );
}

export default Login;
