import { GraphQLClient } from 'graphql-request';

const makeAPI = (token) => {
  const authorizationHeader = (
    token ?
      { authorization: `Bearer ${token}` } :
      null
    );

  const client = new GraphQLClient('https://ypcqtqujo4.execute-api.us-east-1.amazonaws.com/dev/', {
    headers: {
      ...authorizationHeader,
    }
  });

  const fetchAttendance = (id) => client.request(`
    query($id: String!) {
      attendance(id: $id) {
        users {
          user_id
          name
          picture
        }
      }
    }
  `, { id });

  const markAttendance = (id) => client.request(`
    mutation($id: String!) {
      markAttendance(id: $id) {
        event
      }
    }
  `, { id })

  const unmarkAttendance = (id) => client.request(`
    mutation($id: String!) {
      unmarkAttendance(id: $id)
    }
  `, { id })

  return {
    fetchAttendance,
    markAttendance,
    unmarkAttendance,
  };
};

export default makeAPI;
